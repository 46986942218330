<template>
  <div class="relative w-full h-full py-40 min-h-screen bg-gray-700 h-screen">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12">
        <div
          class="relative flex flex-col min-w-0 break-words w-full border-0 text-center"
        >
          <h1 class="text-gray-400" style="font-size: 2rem;">Упс!</h1>
          <p class="text-white" style="font-size: 10rem;">404</p>
          <span class="text-gray-400">Страница не найдена</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'not-found'
}
</script>
